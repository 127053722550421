/* eslint-disable */
$(document).ready(function() {
    $(".my-activity").click(function(event) {
        var total = 0;
        $(".my-activity:checked").each(function() {
            total += parseInt($(this).val());
        });

        if (total == 0) {
            $('#amount').val('$0');
        } else {
            $('#amount').val('$'+total);
        }
    });

    // Rough Average Calculator
    $(".itemWest").click(function(event) {
        var totalLow = 0;
        var totalHigh = 0;
        $(".itemWest:checked").each(function() {
            totalLow += parseInt($(this).attr("value-low"));
            totalHigh += parseInt($(this).attr("value-high"));
        });

        if (totalLow == 0) {
            $('#amountWest').val('$0');
        } else {
            $('#amountWest').val('$'+totalLow+' - '+'$'+totalHigh);
        }

    });
      $(".itemSouthwest").click(function(event) {
        var totalLow = 0;
        var totalHigh = 0;
        $(".itemSouthwest:checked").each(function() {
            totalLow += parseInt($(this).attr("value-low"));
            totalHigh += parseInt($(this).attr("value-high"));
        });

        if (totalLow == 0) {
            $('#amountSouthwest').val('$0');
        } else {
            $('#amountSouthwest').val('$'+totalLow+' - '+'$'+totalHigh);
        }

    });
        $(".itemMidwest").click(function(event) {
        var totalLow = 0;
        var totalHigh = 0;
        $(".itemMidwest:checked").each(function() {
            totalLow += parseInt($(this).attr("value-low"));
            totalHigh += parseInt($(this).attr("value-high"));
        });

        if (totalLow == 0) {
            $('#amountMidwest').val('$0');
        } else {
            $('#amountMidwest').val('$'+totalLow+' - '+'$'+totalHigh);
        }

    });

          $(".itemSouth").click(function(event) {
        var totalLow = 0;
        var totalHigh = 0;
        $(".itemSouth:checked").each(function() {
            totalLow += parseInt($(this).attr("value-low"));
            totalHigh += parseInt($(this).attr("value-high"));
        });

        if (totalLow == 0) {
            $('#amountSouth').val('$0');
        } else {
            $('#amountSouth').val('$'+totalLow+' - '+'$'+totalHigh);
        }

    });

            $(".itemNortheast").click(function(event) {
        var totalLow = 0;
        var totalHigh = 0;
        $(".itemNortheast:checked").each(function() {
            totalLow += parseInt($(this).attr("value-low"));
            totalHigh += parseInt($(this).attr("value-high"));
        });

        if (totalLow == 0) {
            $('#amountNortheast').val('$0');
        } else {
            $('#amountNortheast').val('$'+totalLow+' - '+'$'+totalHigh);
        }

    });

});
