var overlayOpen = function () {
  return $('.page-overlay').hasClass('active')
}

var closePageOverlay = function () {
  $('.page-overlay').removeClass('active')
  $.scrollLock()
}

var addPushState = function () {
  var scrollTop = $('body').scrollTop()

  window.history.replaceState({scrollTop: scrollTop}, null, null)
  window.history.pushState({overlay: 'open'}, null, null)
}

var initPageOverlayCloser = function () {
  window.onpopstate = function (event) {
    if (!overlayOpen()) {
      return
    }

    var scrollTop = event.state.scrollTop

    closePageOverlay()
    if (scrollTop) {
      $('body').scrollTop(scrollTop)
    }
  }

  $(document).on('keyup', function (e) {
    if (!overlayOpen() || e.keyCode !== 27) return

    closePageOverlay()
  })
}

var activateOverlay = function ($elem) {
  $elem.toggleClass('active')
  addPushState()
  $.scrollLock()
  initPageOverlayCloser()
}

jQuery(function () {
  $('.menu-link').on('click', function () {
    activateOverlay($('#page-menu-overlay'))
  })

  $('.page-overlay-close-link').on('click', function () {
    window.history.back()
  })

  $('#mobile-page-menu-link').on('click', function () {
    activateOverlay($('#page-menu-overlay'))
  })

  $('#blog-archive-link').on('click', function () {
    activateOverlay($('#blog-archive-overlay'))
  })

  $('#blog-tags-link').on('click', function () {
    activateOverlay($('#blog-tags-overlay'))
  })

  $('.phone-overlay-link').on('click', function () {
    activateOverlay($('#phone-overlay'))
  })

  $('.location-overlay-link').on('click', function () {
    activateOverlay($('#location-overlay'))
  })

  $('.contact-overlay-link').on('click', function () {
    var $overlay = $('#contact-overlay')
    activateOverlay($overlay)
    initExpandingTextareas($overlay)
  })

  $('.ppc-cta-button').on('click', function () {
    var $overlay = $('#ppc-contact-overlay')
    activateOverlay($overlay)
    initExpandingTextareas($overlay)
  })

  $('.social-accounts-overlay-link').on('click', function () {
    activateOverlay($('#social-accounts-overlay'))
  })

  $('.video-overlay-link').on('click', function () {
    let vid = $('.video-overlay__video video')[0]
    if (!vid.fullscreenElement && window.navigator.userAgent.match(/iPhone|iPod|iPad/i)) {
      if (vid.requestFullscreen) {
        vid.requestFullscreen()
      } else if (vid.mozRequestFullScreen) {
        vid.mozRequestFullScreen()
      } else if (vid.webkitRequestFullscreen) {
        vid.webkitRequestFullscreen()
      } else if (vid.msRequestFullscreen) {
        vid.msRequestFullscreen()
      }
    } else {
      activateOverlay($('#video-overlay'))
    }
  })

  // Fixed menu
  var $window = $(window)
  var $simpleHeader = $('#simple-header')
  var $heroHeader = $('#hero-header')
  var $fixedHeader = $('#fixed-header')
  var bottomOfMenuHeight = 0

  if ($simpleHeader.length) {
    bottomOfMenuHeight = $simpleHeader.height()
  } else if ($heroHeader.length) {
    bottomOfMenuHeight = $heroHeader.height()
  }

  // CANTFIX: improve transition on iPads
  // touch instantly adds nav.  CSS transition is ignored
  $window.on('scroll touchmove', function () {
    if ($window.scrollTop() >= bottomOfMenuHeight) {
      if (!$fixedHeader.hasClass('active')) {
        $fixedHeader.addClass('active')
      }
    } else {
      if ($fixedHeader.hasClass('active') && !overlayOpen()) {
        $fixedHeader.removeClass('active')
      }
    }

    return true
  })

  $window.scroll()

  $('#myTabs a').click(function (e) {
    e.preventDefault()
    $(this).tab('show')
  })
})
