$(() => {
  $('.video-overlay-link').on('click', () => {
    let vid = $('.video-overlay__video video')[0]

    // Start video 500ms after opening overlay
    setTimeout(() => {
      vid.play()
    }, 500)

    const $vidOverlayButton = $('.video-overlay__button')

    function disableCTA () {
      if ($vidOverlayButton.hasClass('video-overlay__button--visible')) {
        $vidOverlayButton.removeClass('video-overlay__button--visible')
      }
    }

    function enableCTA () {
      if ($vidOverlayButton.hasClass('video-overlay__button--visible') === false) {
        $vidOverlayButton.addClass('video-overlay__button--visible')
      }
    }

    vid.addEventListener('pause', enableCTA, false)
    vid.addEventListener('play', disableCTA, false)

    // Pause video when overlay is closed
    $('#video-overlay .close-link a').on('click', () => {
      if (vid.paused === false) { vid.pause() }

      vid.removeEventListener('pause', enableCTA, false)
      vid.removeEventListener('play', disableCTA, false)
    })
  })
})
