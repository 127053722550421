// Ken Burns effect - On Scroll
$(() => {
  let images = document.querySelectorAll('.ken-burns-effect')
  let ticking = false

  // Call rAF to keep adjacent element animations in sync
  function onScroll () {
    if (!ticking) {
      window.requestAnimationFrame(update)
      ticking = true
    }
  }

  // Our animation callback
  function update () {
    let image = null
    let imageTop = []
    const halfWindowHeight = window.innerHeight * 0.5

    // first loop is going to grab
    // the image's top position
    for (let i = 0; i < images.length; i++) {
      image = images[i]
      imageTop[i] = image.getBoundingClientRect().top
    }

    // second loop is going to go through
    // the images and add the ken-burns-effect--scrolled-in-view class
    // remove it otherwise for rescrolling into view
    for (let i = 0; i < images.length; i++) {
      image = images[i]

      // Add animation class when the top of the image
      // is less than or equal to half of the window height
      if (imageTop[i] - halfWindowHeight <= 0) {
        image.classList.add('ken-burns-effect--scrolled-in-view')
      } else {
        image.classList.remove('ken-burns-effect--scrolled-in-view')
      }
    }

    // allow further rAFs to be called
    ticking = false
  }

  // only listen for scroll events on desktop
  // since that's when animation break point occurs
  if (window.innerWidth >= 900) {
    window.addEventListener('scroll', onScroll, false)
  }
})
