((w, d) => {
  function LoopingVideo (selector) {
    const loopingVideos = [...d.querySelectorAll(selector)]

    if (loopingVideos.length === 0) {
      return
    }

    // iOS Safari automatically plays and pauses video
    // https://webkit.org/blog/6784/new-video-policies-for-ios/
    if (!navigator.userAgent.match(/iPhone|iPod|iPad/i)) {
      d.addEventListener('scroll', () => {
        loopingVideos.forEach((video) => {
          // Window height
          const windowHeight = w.innerHeight
          // Tolerance range (amount of video inside viewport)
          const tolerance = 0.5
          // Video position
          const rect = video.getBoundingClientRect()
          // Y-axis tolerance
          const toleranceY = (rect.bottom - rect.top) * tolerance

          // If the video is beyond tolerance range and is playing, pause it.
          // If the video is within tolerance range and is paused, play it.
          if (rect.bottom < toleranceY || windowHeight - rect.top < toleranceY) {
            !video.paused && video.pause()
          } else {
            video.paused && video.play()
          }
        })
      })
    }
  }

  d.addEventListener('DOMContentLoaded', LoopingVideo('video.looping-video'))
})(window, document)
