$(function () {
  function TimePicker (elem) {
    this.options = {shouldSort: false, searchEnabled: false, itemSelectText: ''}

    this.init = function () {
      const choices = new Choices(elem, this.options)
      elem.classList.remove('disabled')
      return choices
    }

    this.init()
  }

  function DateTimeFieldGroups () {
    this.groupSelector = '.consultation-form .date-time-field-group.hidden'
    this.expandLink = document.querySelector('.consultation-form .expand-link')

    this.showExpandLinkUnlessLast = function () {
      if (document.querySelector(this.groupSelector) && this.expandLink) {
        this.expandLink.classList.remove('hidden')
      }
    }

    this.hideExpandLinkIfLast = function (link) {
      if (!document.querySelector(this.groupSelector)) {
        link.classList.add('hidden')
      }
    }

    this.init = function () {
      if (!document.querySelector('.consultation-form')) {
        return
      }

      const groups = this
      const closeLinks = Array.from(document.querySelectorAll('.consultation-form .close-link'))

      if (closeLinks && closeLinks.length) {
        closeLinks.forEach(function (link) {
          link.addEventListener('click', function () {
            const fieldGroup = this.parentNode
            if (fieldGroup) {
              fieldGroup.classList.add('hidden')
              const inputs = fieldGroup.querySelectorAll('input, select')
              if (inputs && inputs.length) {
                inputs.forEach(function (input) {
                  input.disabled = true
                })
              }
            }
            groups.showExpandLinkUnlessLast()
          })
        })
      }

      if (this.expandLink) {
        this.expandLink.addEventListener('click', function () {
          const nextGroup = document.querySelector(groups.groupSelector)
          if (nextGroup) {
            nextGroup.classList.remove('hidden')
            const disabledInputs = Array.from(nextGroup.querySelectorAll('[disabled]'))
            if (disabledInputs && disabledInputs.length) {
              disabledInputs.forEach(function (input) {
                input.disabled = false
              })
            }
            const timeInput = nextGroup.querySelector('select.disabled')
            if (timeInput) {
              TimePicker(timeInput)
            }
          }
          groups.hideExpandLinkIfLast(this)
        })
      }
    }

    this.init()
  }

  initValidation('contact-form')
  initValidation('consultation-form')

  // date picker
  $('.consultation-form-date').flatpickr({
    dateFormat: 'm/d/Y',
    nextArrow: '&#9654',
    prevArrow: '&#9664'
  })

  // selects
  const firstTimePicker = document.getElementById('contact_time_0')
  if (firstTimePicker) {
    TimePicker(firstTimePicker)
  }

  const locationPicker = document.querySelector('select#contact-location')
  if (locationPicker) {
    TimePicker(locationPicker)
  }

  DateTimeFieldGroups()
})
