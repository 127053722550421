// Ken Burns effect - Images
$(function () {
  function addKenBurnsEffectClass (images) {
    images.addClass('ken-burns-effect')
  }

  function addParentFloatClass (images) {
    $.each(images, function (i, image) {
      if (image.className.indexOf('img-left') >= 0) {
        image.parentElement.className += ' img-left'
      } else if (image.className.indexOf('img-right') >= 0) {
        image.parentElement.className += ' img-right'
      }
    })
  }

  const $content50Images = $('.content-50 img')
  const $content33Images = $('.content-33 img')
  const $contentImages = $('.content img')
  const $profileImages = $('.profile-menu .profile-menu__image')
  const $content75Images = $('.content-75-25 img')

  const contentImages = [$contentImages, $content50Images, $content33Images]
  const kenBurnsEffectImages = [$content75Images, $profileImages]

  kenBurnsEffectImages.forEach(function (images) {
    addKenBurnsEffectClass(images)
  })

  contentImages.forEach(function (images) {
    addKenBurnsEffectClass(images)
    addParentFloatClass(images)
  })

  $('.page-header__hero-image').imagesLoaded({background: true}, function () {
    $('.page-header__hero-image').addClass('ken-burns-effect--on-load')
  })

  if (window.innerWidth >= 1200) {
    $('.contact.fragment.back').addClass('ken-burns-effect')
  }
})
