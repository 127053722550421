// Append the timeline quote to the first item on desktop screens
// Allows the quote to be sized dynamically
$(function () {
  function appendTimelineQuote () {
    const timelines = $('.timeline')
    $.each(timelines, (idx, timeline) => {
      let $firstTimelineItemWrapper = $(timeline).find('.timeline-item-wrapper:first-of-type')
      let $timelineItemQuote = $(timeline).find('.timeline__quote-wrapper--desktop')
      $firstTimelineItemWrapper.append($timelineItemQuote)
    })
  }

  if (window.innerWidth >= 900) {
    appendTimelineQuote()
    window.addEventListener('resize', appendTimelineQuote)
  }
})
