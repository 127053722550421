$(function () {
  function setupVideoCaption ($video, videoType) {
    let meMode = 'auto'
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

    if (isFirefox) {
      meMode = 'auto_plugin'
    }

    return new MediaElementPlayer($video, {
      type: videoType,
      mode: meMode,
      startVolume: 0.8,
      AndroidUseNativeControls: true,
      iPhoneUseNativeControls: true,
      iPadUseNativeControls: true,
      pluginPath: '//d4xmq39929kw8.cloudfront.net/mediaelement-2.14/',
      plugins: ['flash'],
      flashName: 'flashmediaelement-cdn.swf'
    })
  }

  function setupFitVids (selector) {
    $(selector).each(function () {
      let $video = $(this)

      if ($video.context.tagName !== 'VIDEO') {
        $video = $(this).find('video')
      }

      let videoType = $video.data('type')

      if ($video.length) {
        setupVideoCaption($video, videoType)
      }

      $(this).fitVids()
    })
  }

  const selectors = ['.video.fragment', '.video-wide.fragment', '#video-player']

  selectors.forEach(selector => setupFitVids(selector))
})
