$(function () {
  const inputTags = [
    '#Site_phone_number_in',
    '#Site_phone_cta_in',
    '#Site_hero_stacked_in',
    '.url_input_fields #Site_cta_url_in',
    '#Site_cta_text_in',
    '#Site_cta_position_in',
    '#Site_cta_position_mobile_in',
    '#Page_hero_desktop_opacity_in',
    '#Page_hero_mobile_opacity_in',
    '#Site_typekit_hero_cta_title_font_family_in',
    '#Site_typekit_hero_cta_title_font_size_in',
    '#Site_typekit_hero_cta_title_font_size_mobile_in',
    '#Site_typekit_hero_cta_title_line_height_in',
    '#Site_typekit_hero_cta_title_font_weight_in',
    '#Site_typekit_hero_cta_subtitle_font_family_in',
    '#Site_typekit_hero_cta_subtitle_font_size_in',
    '#Site_typekit_hero_cta_subtitle_font_size_mobile_in',
    '#Site_typekit_hero_cta_subtitle_line_height_in',
    '#Site_typekit_hero_cta_subtitle_margin_top_in',
    '#Site_typekit_hero_cta_subtitle_padding_bottom_in',
    '#Site_typekit_hero_cta_subtitle_padding_bottom_mobile_in',
    '#Site_typekit_hero_cta_subtitle_font_weight_in',
    '#Page_hero_video_in',
    '#Page_hero_video_opacity_in',
    '#Page_sub_heading_in',
    '#Page_hero_image_position_in',
    '#Page_hero_image_y_position_in',
    '#Page_hero_mobile_image_x_position_in',
    '#Page_hero_mobile_image_y_position_in',
    '#Page_hero_caption_in',
    '#Page_hero_video_overlay_in',
    '#cke_Page_heading_in',
    '#Page_home_page_logo_image_in'
  ]

  function removeDialogInputs (inputIds) {
    inputIds.forEach(inputId => {
      let parent = $(inputId).parent()

      if (parent.hasClass('url_input_fields') || parent.hasClass('image_input_fields')) {
        parent.parent().hide()
      } else {
        parent.hide()
      }
    })
  }

  // Remove non-related inputs from the `Hero` config on non `home-page`s
  const bodyClassName = document.getElementsByTagName('body')[0].className
  const floatWindow = document.getElementsByClassName('xlucid_float_window_region')[0]

  if (!bodyClassName.includes('home') && !!floatWindow) {
    floatWindow.addEventListener('DOMNodeInserted', () => {
      removeDialogInputs(inputTags)
    }, false)
  }
})
