// Make `div`s clickable
// Helps formatting for DocShop HTMLFormatter when hrefs wrap block-level elements
// https://ctrlq.org/code/19639-turn-div-clickable-link
$(function () {
  function clickableDivs (element) {
    // Open link in same window
    $(element).click(function () {
      let href = $(this).attr('href') || $(this).find('a:first-child').attr('href')

      if (href === 'javascript:void(0)' || href === '#' || typeof href === 'undefined') {
        return false
      }

      window.location = href
      return false
    })

    // Show URL on mouse hover (depends on browser)
    // Add `js-link-hover` class to make div appear as a link on hover
    // first function `mouseenter` event, second function `mouseleave` event
    $(element).hover(
      function () {
        let href = $(this).attr('href') || $(this).find('a:first-child').attr('href')

        if (href !== 'javascript:void(0)' && href !== '#' && typeof href !== 'undefined') {
          $(this).addClass('js-link-hover')
        }

        window.status = href
      },
      function () {
        window.status = ''
        $(this).removeClass('js-link-hover')
      }
    )
  }

  const elements = ['.icon-menu__link', '.menu-photo__link']
  elements.forEach(element => clickableDivs(element))

  // If .image-menu__link has an attribute of `data-href`,
  // then on click go to that url in same window.
  $('.image-menu__link[data-href]').on('click', function () {
    window.location = $(this).attr('data-href')
  })
  // If .image-menu__link has an attribute of `data-href`,
  // then on hover change cursor to pointer.
  $('.image-menu__link[data-href]').hover(function () {
    $(this).css('cursor', 'pointer')
  })
})
