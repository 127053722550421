// resize Lucid UI groupboxes when image is lazy loaded
$(function () {
  var images = Array.prototype.slice.call(document.getElementsByTagName('img'))
  if (images.length >= 1 && 'Lucid' in window) {
    images.forEach(function (image) {
      image.addEventListener('load', function () {
        Lucid.App.vent.trigger('image:lazyload', this)
      })
    })
  }
})
