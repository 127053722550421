$(function () {
  $('.review-description-wrapper').first().addClass('first-review')
  $('.review-description-wrapper').last().addClass('last-review')

  const review = $('.review')

  if (review.length < 3) {
    review.children().addClass('review-50-50')

    const reviews = $('.review-50-50')

    // Give the first review an id as the container
    // then remove the data-lucid-group
    reviews.eq(0).parent().attr('id', 'review-container')
    reviews.eq(0).parent().removeAttr('data-lucid-group')

    // Give the first 50-50 review an id
    // then enable wsyiwyg editing with attribute
    reviews.eq(0).attr('id', 'first-review')
    reviews.eq(0).attr('data-lucid-group', 'Review 1')

    // Give the second 50-50 review an id
    // then enable wsyiwyg editing with attribute
    reviews.eq(1).attr('id', 'second-review')
    reviews.eq(1).attr('data-lucid-group', 'Review 2')

    // Add the second review to the reviews container
    const reviewsContainer = reviews.eq(0).parent()
    const secondReview = reviews.eq(1)
    reviewsContainer.append(secondReview)

    // Remove empty div of second review
    review.eq(1).remove()
  }
})
