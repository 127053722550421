$(function () {
  var loadLocationImages = function ($container) {
    $container.find('img.delayed-image').each(function () {
      var $img = $(this)

      var src = $img.data('src')
      if (!src) { return }

      $img.attr('src', src)
      $img.removeClass('delayed-image')
    })
  }

  $('.location-overlay-link').one('click', function () {
    loadLocationImages($('#location-overlay'))
  })
})
