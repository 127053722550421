$(function () {
  var $heroSimple = $('.page-header__hero--simple')
  var $hero = $('.page-header__hero')
  var bottomOfMenuHeight = 0

  var $fixedHeader = $('.fixed-header')
  if ($heroSimple.length) {
    bottomOfMenuHeight = $heroSimple.height()
  } else if ($hero.length) {
    bottomOfMenuHeight = $hero.height()
  }

  function addFixedClass (e) {
    if ($window.scrollTop() >= bottomOfMenuHeight) {
      $fixedHeader.addClass('fixed-nav')
    } else {
      if ($fixedHeader.hasClass('active') && !overlayOpen()) {
        $fixedHeader.removeClass('fixed-nav')
      }
    }
  }

  var $window = $(window)
  $window.on('scroll', addFixedClass)
})
